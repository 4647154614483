import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'

import { informationSectionData } from '../data/project-data--homelessness-in-toronto'

const HeaderImage = styled.div`
	height: auto;

	margin-bottom: 12px;

	& > * {
		width: auto;
		max-height: 100vh;
		/* height: fill-available; */
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const HousingHomlessnessWaitLists = ({ data }) => (
	<Layout>
		<SEO title="Housing, Homelessness, and Wait Lists in Toronto" />
		<HeaderImage>
			<Img
				fluid={data.homelessnessImage.childImageSharp.fluid}
				alt="A data visualization of the increasing homelessness in Toronto."
				imgStyle={{ objectFit: 'contain' }}
			/>
		</HeaderImage>
		<HeadingGroup>
			<Title>Housing, Homelessness, and Wait Lists in Toronto</Title>
			<Subtitle>Visualizing Public Toronto Data</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

HousingHomlessnessWaitLists.propTypes = {
	data: PropTypes.any.isRequired
}

export const pageQuery = graphql`
	query {
		homelessnessImage: file(
			relativePath: { regex: "/housing-homelessness-and-wait-lists.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default HousingHomlessnessWaitLists
